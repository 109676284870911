// React imports
import { Route, Routes, Outlet } from 'react-router-dom';
import { Suspense, lazy, useEffect } from 'react';
import { Hearts } from 'react-loader-spinner';
import ReactGA from 'react-ga';

const PublicLayout = lazy(() => import('./PublicLayout'));
const Card = lazy(() => import('./components/Card/Card'));

const App = () =>{
  const setGA = () => {
    ReactGA.initialize('G-JJ35LKKPT6');
    ReactGA.pageview('Init Page View');
  }

  useEffect(() =>{
    setGA();
  }, []);
  return (
  <div className='app_wrapper'>
    <title>Like A Bandit</title>
    <link rel="icon" href="/favicon.png" />
    <link href="/favicon.png" />
    <Suspense fallback={(
      <div className="overlay">
        <Hearts
          color="#F05F40"
          height={100}
          width={100}
        />
      </div>
    )}
    >
      <Routes>
        <Route path='/cardmaker/:_id' element={<Card />} />
        <Route path='/*' element={<PublicLayout />} />
      </Routes>
      <Outlet />
    </Suspense>
  </div>
)
    }
export default App
